<!-- 人才列表 -->
<template>
  <div class="talents_list">
    <!-- 搜索框 -->
    <header class="inp">
      <Button type="warning" size="large" style="width:130px" @click="search()">搜索</Button>
      <Input v-model="obj.name" @on-enter="search()" size="large" prefix="ios-search" placeholder="输入关键词，搜索相关人才">
      </Input>
    </header>

    <div class="search_text">
      <div class="label">猜你想搜:</div>
      <div>
        <Tag style="cursor: pointer;" v-for="(item,index) in Position" :checked="Position_Tag == item.name" :key="index" checkable color="warning" @on-change="(val)=>PositionTag(val,item)">{{item.name}}</Tag>
        <br>
        <Tag style="cursor: pointer;" v-for="(item,index) in Position_tow" :checked="Position_tow_Tag == item.name" :key="item.id" checkable color="warning" @on-change="(val)=>PositionTag_tow(val,item)">{{item.name}}</Tag>
      </div>
    </div>

    <div class="talents_number">
      <img src="@/assets/images/rencai_flag.png" alt="">
      <p>您已查看了&nbsp;<span>{{talents_number}}</span>&nbsp;个人才</p>
    </div>

    <div class="condition">

      <div class="condition_in" style="margin-bottom:30px">
        <p style="width:90px">区域</p>
        <Cascader :data="workArea_data" trigger="hover" style="width: 200px;" @on-change='new_street_change'></Cascader>
      </div>

      <div class="condition_in" style="margin-bottom:30px">
        <p style="width:90px">性别</p>
        <Tag style="cursor: pointer;" v-for="(item,index) in sex" :checked="sex_Tag == item.name" :key="index" checkable color="warning" @on-change="sexTag(item)">{{item.name}}</Tag>
      </div>

      <div class="condition_in">
        <p style="width:90px">学历要求</p>
        <Tag style="cursor: pointer;" v-for="(item,index) in education" :checked="education_Tag == item.name" :key="index" checkable color="warning" @on-change="educationTag(item)">{{item.name}}</Tag>
      </div>

    </div>

    <div class="list">
      <Spin size="large" fix v-if="spinShow"></Spin>
      <ul>
        <li v-for="(item,index) in list" :key="index">
          <Row>
            <Col span="2" style="text-align:center">
            <!-- <img :src="require(`@/assets/images/${Icon(item.sex)}`)" alt=""> -->
            <img :src="item.icon" alt="">
            </Col>
            <Col span="20">
            <p style="color:#000;">
              <span>{{item.name[0]}}{{item.sex == 1?'先生':'小姐'}}</span>
              <Tag color="green" v-if="item.readingStatus && item.readType == 1">已查看</Tag>&nbsp;
              <Tag color="blue" v-if="item.readingStatus && item.readType == 2">已投递</Tag>&nbsp;
            </p>
            <p style="margin:15px 0;color:#000;">
              <span>{{jsGetAge(item.birthDate)}}岁丨</span>
              <span>{{working_age(item.workedDate)}}年工作经验丨</span>
              <span>{{item.education}}</span>

            </p>
            <p>
              <span style="color:#a7a7a7">
                期望：
                {{item.fullTime?item.fullTime.trade[0]+' | ':''}}
                {{item.fullTime?item.fullTime.jobIntention[0]+' | ':''}}
                {{item.fullTime?item.fullTime.workplace[0]+'-':''}}
                {{item.fullTime?item.fullTime.workplace[1]+'-':''}}
                {{item.fullTime?item.fullTime.workplace[2]+' | ':''}}
                <!-- {{Position_Tag == '小时工'?item.temporary?item.fullTime.pay:''}} -->
                {{Position_Tag == '小时工'?item.temporary?item.temporary.salary+'元/小时':'':item.fullTime?item.fullTime.pay:''}}
              </span>
            </p>
            </Col>
            <Col span="2">
            <!-- <Button type="warning" long @click="$router.push({name:'talents_details',query:{id:item.userId}})" target='_blank'>前去查看</Button> -->

            <!-- <router-link :to="{name:'talents_details',query:{id:item.userId}}" target="_blank"> -->
            <router-link :to="{name:'talents_details',query:{id:item.userId}}">
              <Button style="min-width:80px" type="warning" long>前去查看</Button>
            </router-link>

            </Col>
          </Row>

        </li>
      </ul>
    </div>

    <footer>
      <div @click="changeList" v-if="list.length>0">
        <img src="@/assets/images/rencai_change.png" alt="">
        <p>换一换</p>
      </div>
      <p v-else style="margin-top:100px;text-align:center;font-size:20px">暂无数据</p>
    </footer>

  </div>
</template>

<script>

export default {
  data() {
    return {
      spinShow: false,
      //已查看人才数
      talents_number: "",
      Position_Tag: "",
      Position_tow_Tag: "",
      // 职位类别1
      Position: [
        {
          name: "普工",
          id: "普工"
        },
        {
          name: "技能人才",
          id: "技能人才"
        },
        {
          name: "文职/管理",
          id: "文职/管理"
        },
        {
          name: "市场营销",
          id: "市场营销"
        },
        {
          name: "物流电商",
          id: "物流电商"
        },
        {
          name: "物业后勤",
          id: "物业后勤"
        },
        {
          name: "小时工",
          id: "小时工"
        },

      ],
      // 职位类别2
      Position_tow: [
        {
          name: "叉车工",
          id: "叉车工"
        },
        {
          name: "电工",
          id: "电工"
        },
        {
          name: "焊工",
          id: "焊工"
        },
        {
          name: "钳工",
          id: "钳工"
        },
        {
          name: "司机",
          id: "司机"
        },
        {
          name: "财会",
          id: "财会"
        },
        {
          name: "文职",
          id: "文职"
        },
      ],
      education_Tag: "不限",
      //学历要求
      education: [
        {
          name: '不限',
          id: '',
        },
        {
          name: '初中',
          id: '初中',
        },
        {
          name: '高中/中专',
          id: '高中/中专',
        },
        {
          name: '大专',
          id: '大专',
        },
        {
          name: '本科及以上',
          id: '本科及以上',
        },

      ],
      sex_Tag: "不限",
      //性别要求
      sex: [
        {
          name: '不限',
          id: ''
        },
        {
          name: '男',
          id: '1'
        },
        {
          name: '女',
          id: '2'
        },
      ],
      //地区配置项
      workArea_data: [],



      obj: {
        name: "",
        address: "",
        sex: "",
        education: "",
        category: "",
        certificates: "",
        enterpriseId: localStorage.getItem("firm_enterpriseId"),
        page: 1,
        size: 6
      },

      list: [],

    }
  },
  computed: {
    working_age() {
      return (data) => {
        var workedDate = data[0] + data[1] + data[2] + data[3];
        var y = new Date().getFullYear()
        return y - workedDate
      }
    }
  },
  methods: {
    //换一换
    changeList() {
      document.getElementsByClassName("ivu-layout")[1].scrollTop = 0
      this.obj.page++
      this.get_data()
    },

    //类别1选择后
    PositionTag(val, item) {
      if (val) {
        this.Position_tow_Tag = ''
        this.obj.certificates = ''
        this.Position_Tag = item.name
        this.obj.category = item.id

      } else {
        this.obj.category = ''
      }
      this.search()

    },
    //类别1选择后
    PositionTag_tow(val, item) {
      if (val) {
        this.Position_Tag = ''
        this.obj.category = ''

        this.Position_tow_Tag = item.name
        this.obj.certificates = item.id
      } else {
        this.obj.certificates = ''
      }

      this.search()
    },
    //地区
    new_street_change(val) {
      this.obj.address = val.join('-')
      this.search()
    },
    //性别
    sexTag(item) {
      this.sex_Tag = item.name
      this.obj.sex = item.id
      this.search()
    },
    //学历
    educationTag(item) {
      this.education_Tag = item.name
      this.obj.education = item.id
      this.search()
    },

    //选项改变重置page
    search() {
      this.obj.page = 1
      this.get_data()
    },

    //封装区域数据递归
    recursion(arr) {
      arr.forEach((item, index) => {
        item.value = item.name
        item.label = item.name
        if (item.subareas) {
          item.children = item.subareas
          this.recursion(item.children)
        }
      })
    },


    //封装获取数据
    get_data() {
      this.spinShow = true
      this.app("enterpriseOperators", "selectRecommendationLetter", this.obj).then(res => {
        this.spinShow = false
        console.log(res);

        if (this.obj.page != 1 && !res.result.length) {
          this.search()
        }

        this.list = res.result
        this.list.forEach((item, index) => {
          //生成随机头像
          if (item.sex == 1) {
            let num = Math.ceil(Math.random() * 1000000000 % 9);
            if (num == 1 || num == 2 || num == 3) {
              item.icon = require(`@/assets/images/man_1.png`)
            } else if (num == 4 || num == 5 || num == 6) {
              item.icon = require(`@/assets/images/man_2.png`)
            } else {
              item.icon = require(`@/assets/images/man_3.png`)
            }
          } else {
            let num = Math.ceil(Math.random() * 1000000000 % 9);
            if (num == 1 || num == 2 || num == 3) {
              item.icon = require(`@/assets/images/women_1.png`)
            } else if (num == 4 || num == 5 || num == 6) {
              item.icon = require(`@/assets/images/women_2.png`)
            } else {
              item.icon = require(`@/assets/images/women_3.png`)
            }
          }

        })


      })
    },



    //封装获取已查看人才数
    get_talents_number() {
      this.app('enterpriseOperators', 'talentedPeopleList', {
        enterpriseId: localStorage.getItem("firm_enterpriseId"),
        isCount: true
      }).then(res => {
        this.talents_number = res.data
      })
    }

  },
  created() {
    this.obj.name = this.$route.params.name
    //获取职位类别数据
    // this.get_positionType()
    this.get_data()
    //获取已查看人才数
    this.get_talents_number()

    //获取后端区域数组
    this.app("enterpriseOperators", "getAreas", {}).then(res => {
      this.recursion(res.data)
      this.workArea_data = res.data
      console.log(this.workArea_data);
    })
  }
}
</script>

<style lang="less" scoped>
@import "./talents_list.less";
</style>
